import React, { useState } from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import { connect } from 'react-redux'

import { Button } from '../common'

import { getSelectedDocument } from '../../modules/organizations'

import { setOrganisationDocumentsModalVisible } from '../../modules/ui'
import assets from '../../assets'

import { StaticFilesURL } from '../../API'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: space-between;
  padding: 20px 10px;
`

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
`

const Main = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const CancelButtonContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  height: 25px;
`

const BtnArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  font-size: 30px;
  padding: 0px 40px;
  margin: 0px 60px;
  cursor: pointer;
  width: 40px;
  color: white;
  padding: 40px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin: 0;
    padding: 15px;
  }
  
`

const ImgArea = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ImgLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 45%;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    max-width: 90%;
  }
`

const ImgContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const TextContainer = styled.div`
  color: white;
  height: 160px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 15px;
`

const FileNumber = styled(TextContainer)`
  font-size: 15px;
  margin-top: 15px;
`

const ButtonsContainer = styled.div`
  display: flex;
  background: #fff;
  padding: 20px 0px;
  border-radius: 8px;
  width: 100%;
  justify-content: center;
`

const ConfirmButton = styled.button`
  height: 36px;
  border: none;
  background: linear-gradient(135deg, #f49355 0%, #f0640c 100%);
  box-shadow: 0px 4px 10px rgba(247, 137, 68, 0.55);
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  color: white;
  margin-right: 15px;
  width: 95px;
`

const CancelButton = styled(ConfirmButton)`
  background: linear-gradient(135deg, #b6b6b6 0%, #636363 100%);
`

const DeleteIcon = styled.img.attrs({ src: assets.img.deleteWhite })`
  height: 25px;
  cursor: pointer;
`

const ArrowRight = styled.img.attrs({ src: assets.img.vectorRight })``

const ArrowLeft = styled.img.attrs({ src: assets.img.vectorLeft })``

const DocumentsPopupDump = ({ cancel, selectedDocuments }) => {
  const [selectedImage, setImage] = useState(0)

  const next = () => {
    if (selectedImage < selectedDocuments.length - 1) {
      setImage(selectedImage + 1)
    }
  }

  const prev = () => {
    if (selectedImage > 0) {
      setImage(selectedImage - 1)
    }
  }

  return (
    <Container>
      <Header>
        <TextContainer>{selectedDocuments[selectedImage].title}</TextContainer>
        <FileNumber>
          {selectedImage + 1 + '/' + selectedDocuments.length}
        </FileNumber>
        <CancelButtonContainer onClick={cancel}>
          <DeleteIcon />
        </CancelButtonContainer>
      </Header>
      <Main>
        <BtnArea onClick={prev}>
          <ArrowLeft />
        </BtnArea>
        <ImgLink
          href={`${StaticFilesURL}/${selectedDocuments[selectedImage].uri}`}
          download
        >
          <ImgContainer>
            <ImgArea src={selectedDocuments[selectedImage].uri}/>
          </ImgContainer>
        </ImgLink>
        <BtnArea onClick={next}>
          <ArrowRight />
        </BtnArea>
      </Main>
    </Container>
  )
}

const OrganizationDocumentsPopup = R.compose(
  connect(
    R.applySpec({
      selectedDocuments: getSelectedDocument,
    }),
    {
      cancel: setOrganisationDocumentsModalVisible,
    },
  ),
)(DocumentsPopupDump)

export default OrganizationDocumentsPopup
