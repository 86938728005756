import { takeLatest, call, all, put, select } from 'redux-saga/effects'
import {
  createUserRequest,
  createUserSuccess,
  createUserFailure,
  deleteUserRequest,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
  getAmoRolesRequest,
  getAmoRolesSuccess,
  getAmoRolesFailure,
  getAmoUsersFailure,
  getAmoUsersRequest,
  getAmoUsersSuccess,
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  setUserStatusRequest,
  setUserStatusSuccess,
  setUserStatusFailure,
  setUserRequest,
  setUserSuccess,
  setUserFailure,
  selectRole,
  getAvaiableOrganisationsRequest,
  getAvaiableOrganisationsSuccess,
  getAvaiableOrganisationsFailure,
  getAccessListSuccess,
  getAccessListFailure,
  getAccessListRequest,
  getRoleSuccess,
  getRoleFailure,
  getRoleRequest,
  deleteRoleSuccess,
  deleteRoleFailure,
  deleteRoleRequest,
  createRoleSuccess,
  createRoleFailure,
  createRoleRequest,
  updateRoleSuccess,
  updateRoleFailure,
  updateRoleRequest,
  setAccessSuccess,
  setAccessFailure,
  setAccessRequest,
  deleteAccessSuccess,
  deleteAccessFailure,
  getAccessListForUserSuccess,
  getAccessListForUserFailure,
  getAccessListForUserRequest,
  getActiveSortSuccess,
  getMeSuccess,
  getMeRequest,
} from './duck'
import { getSelectedRole } from './selectors'
import { getToken } from '../auth'
import * as UsersManager from './UsersManager'

function* getMeSaga() {
  try {
    const token = yield select(getToken)
    const me = yield call(UsersManager.getMe, { token })
    yield put(getMeSuccess(me))
  } catch (ex) {
    yield put(getAccessListForUserFailure(ex))
  }
}

function* getUsersSaga({ payload: { direction, page } }) {
  try {
    const documentHeight = document.body.clientHeight
    const filters = `sortBy=registrationTime${
      page || page === 0
        ? `&countOnPage=${Math.round(documentHeight / 47)}&page=` + page
        : ''
    }${direction ? '&direction=' + direction : ''}`

    const token = yield select(getToken)
    const data = {
      filters,
      token,
    }

    const users = yield call(UsersManager.getUsers, data)

    const userList = users.results ? users.results : users

    yield put(getUsersSuccess(userList))
  } catch (ex) {
    yield put(getUsersFailure(ex))
  }
}

function* getAccessListSaga() {
  try {
    const token = yield select(getToken)
    const accessList = yield call(UsersManager.getAccessList, { token })

    const list = accessList.results ? accessList.results : accessList

    yield put(getAccessListSuccess(list))
  } catch (ex) {
    yield put(getAccessListFailure(ex))
  }
}

function* getAccessListForUserSaga() {
  try {
    const token = yield select(getToken)
    const accessList = yield call(UsersManager.getAccessListForUser, { token })

    yield put(getAccessListForUserSuccess(accessList))
  } catch (ex) {
    yield put(getAccessListForUserFailure(ex))
  }
}

function* getRoleSaga({ payload: { roleId } }) {
  try {
    const token = yield select(getToken)
    const role = yield call(UsersManager.getRole, { roleId, token })

    const roleData = role.results ? role.results : role

    yield put(getRoleSuccess(roleData))
  } catch (ex) {
    yield put(getRoleFailure(ex))
  }
}

function* deleteRoleSaga({ payload: { roleId } }) {
  try {
    const token = yield select(getToken)
    yield call(UsersManager.deleteRole, { roleId, token })
    yield put(getAmoRolesRequest())
    yield put(deleteRoleSuccess())
  } catch (ex) {
    yield put(getAmoRolesRequest())
    yield put(deleteRoleFailure(ex))
  }
}

function* setAccessSaga({ payload: { roleId, accessId } }) {
  try {
    const body = {
      roleId,
      accessId,
    }
    const token = yield select(getToken)
    yield call(UsersManager.setAccess, {
      body,
      token,
    })

    yield put(setAccessSuccess())
  } catch (ex) {
    yield put(setAccessFailure(ex))
  }
}

function* setUserStatusSaga({ payload: { userId, status } }) {
  try {
    const token = yield select(getToken)
    yield call(UsersManager.setUserStatus, { userId, status, token })
    yield put(setUserStatusSuccess())
  } catch (ex) {
    yield put(setUserStatusFailure(ex))
  }
}

function* createRoleSaga({ payload: { body } }) {
  try {
    const token = yield select(getToken)
    yield call(UsersManager.createRole, { body, token })
    yield put(getAmoRolesRequest())
    yield put(createRoleSuccess())
  } catch (ex) {
    yield put(getAmoRolesRequest())
    yield put(createRoleFailure(ex))
  }
}

function* updateRoleSaga({
  payload: { body, roleId, addAccess, deleteAccess },
}) {
  try {
    const token = yield select(getToken)

    for (let i = 0; i < addAccess.length; i++) {
      const bodyDump = {
        roleId,
        accessId: addAccess[i],
      }
      yield call(UsersManager.setAccess, { body: bodyDump, token })
    }
    for (let i = 0; i < deleteAccess.length; i++) {
      const bodyDump = {
        roleId,
        accessId: deleteAccess[i],
      }
      yield call(UsersManager.deleteAccess, { body: bodyDump, token })
    }

    yield call(UsersManager.updateRole, { roleId, body, token })

    yield put(setAccessSuccess())
    yield put(deleteAccessSuccess())
    yield put(selectRole('Все'))
    yield put(getAmoRolesRequest())
    yield put(updateRoleSuccess())
  } catch (ex) {
    yield put(selectRole('Все'))
    yield put(getAmoRolesRequest())
    yield put(setAccessFailure())
    yield put(deleteAccessFailure())
    yield put(updateRoleFailure(ex))
  }
}

function* setUserSaga({ payload }) {
  try {
    const token = yield select(getToken)
    yield call(UsersManager.setUser, { body: payload, token })
    yield put(setUserSuccess())
  } catch (ex) {
    yield put(setUserFailure(ex))
  }
}

function* getAmoUsersSaga({ payload }) {
  try {
    const token = yield select(getToken)
    const selectedRole = yield select(getSelectedRole)
    const roleFilters =
      selectedRole !== 'Все'
        ? `&filterKey=role&filterTitle=${selectedRole}`
        : ''
    const sortKey = payload?.sortKey ? payload.sortKey : 'fio'
    const sortDirection = payload?.sortDirection ? payload.sortDirection : 'asc'
    const filters =
      `sortKey=${sortKey}&sortDirection=${sortDirection}` + roleFilters

    const data = {
      token,
      filters,
    }

    const users = yield call(UsersManager.getAmoUsers, data)
    yield put(getActiveSortSuccess({ sortKey, sortDirection }))
    yield put(getAmoUsersSuccess(users))
  } catch (ex) {
    console.log(ex)
    yield put(getAmoUsersFailure(ex))
  }
}

function* getAmoRolesSaga({}) {
  try {
    const token = yield select(getToken)
    const data = {
      token,
    }

    const roles = yield call(UsersManager.getAmoRoles, data)
    yield put(getAmoRolesSuccess(roles))
  } catch (ex) {
    yield put(getAmoRolesFailure())
  }
}

function* getAvaiableOrganisationsSaga({}) {
  try {
    const token = yield select(getToken)
    const data = {
      token,
    }

    const organisations = yield call(UsersManager.getAmoOrganisations, data)
    yield put(getAvaiableOrganisationsSuccess(organisations))
  } catch (ex) {
    yield put(getAvaiableOrganisationsFailure())
  }
}

function* updateUserSaga({
  payload: {
    id,
    fio,
    password,
    phone,
    email,
    roles,
    currentOrganization,
    position,
    resetForm
  },
}) {
  try {
    const accessToken = yield select(getToken)
    const phoneWithCode = phone ? '7' + phone.substring(2) : ''

    const body = {
      id: id,
      fio: fio ? fio : '-',
      password,
      phone: phoneWithCode ? `+${phoneWithCode.replace(/\D/g, '')}` : undefined,
      email: email ? email : '-',
      currentOrganization,
      position,
    }

    const bodyForRolesUpdate = {
      userId: id,
      roles: [...roles].map(item => item.id),
    }

    yield call(UsersManager.updateUser, {
      body,
      accessToken,
    })

    yield call(UsersManager.updateUserRoles, {
      body: bodyForRolesUpdate,
      accessToken,
    })

    yield put(getAmoUsersRequest())
    yield call(resetForm)
    yield put(updateUserSuccess())
  } catch (ex) {
    yield put(updateUserFailure(ex))
  }

  //yield put(setTaskModalVisible(false))
}

function* createUserSaga({
  payload: { fio, password, phone, email, organizationId, roles, position, resetForm},
}) {


  const phoneWithCode = '7' + phone.substring(2)
  try {
    const accessToken = yield select(getToken)
    const body = {
      email: email ? email : '-',
      fio: fio ? fio : '-',
      organizationId: organizationId,

      password,
      phone: `+${phoneWithCode.replace(/\D/g, '')}`,
      position: position ? position : '-',

      roles: [...roles.values()].map(item => item.id),
    }

    yield call(UsersManager.createUser, {
      body,
      accessToken,
    })

    yield put(getAmoUsersRequest())

    yield put(createUserSuccess())
    yield call(resetForm)
  } catch (ex) {
    yield put(createUserFailure(ex))
  }

  //yield put(setTaskModalVisible(false))
}

function* deleteUserSaga({ payload: { userId } }) {
  try {
    const accessToken = yield select(getToken)
    const body = {
      id: userId,
    }

    yield call(UsersManager.deleteUser, {
      body,
      accessToken,
    })

    yield put(getAmoUsersRequest())
  } catch (ex) {
    console.log(ex)
  }
}

function* usersSaga() {
  yield all([
    takeLatest(getUsersRequest, getUsersSaga),
    takeLatest(getAccessListRequest, getAccessListSaga),
    takeLatest(getAccessListForUserRequest, getAccessListForUserSaga),
    takeLatest(getRoleRequest, getRoleSaga),
    takeLatest(deleteRoleRequest, deleteRoleSaga),
    takeLatest(setAccessRequest, setAccessSaga),
    takeLatest(createRoleRequest, createRoleSaga),
    takeLatest(updateRoleRequest, updateRoleSaga),
    takeLatest(setUserStatusRequest, setUserStatusSaga),
    takeLatest(setUserRequest, setUserSaga),
    takeLatest(getAmoUsersRequest, getAmoUsersSaga),
    takeLatest(getAmoRolesRequest, getAmoRolesSaga),
    takeLatest(updateUserRequest, updateUserSaga),
    takeLatest(createUserRequest, createUserSaga),
    takeLatest(deleteUserRequest, deleteUserSaga),
    takeLatest(selectRole, getAmoUsersSaga),
    takeLatest(getMeRequest, getMeSaga),
    takeLatest(getAvaiableOrganisationsRequest, getAvaiableOrganisationsSaga),
  ])
}

export default usersSaga
