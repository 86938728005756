import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { StaticFilesURL } from '../../../../API'

import assets from '../../../../assets'

const DocsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(11, 215, 129, 1);
  cursor: pointer;
  position: relative;
`

const DocsStyled = styled.div`
  z-index: 200222;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 20px 80px 20px 20px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.12);
  border-radius: 8px;
  top: -20px;
  left: 70px;
`
const RightArrow = styled.img.attrs({ src: assets.img.arrow })`
  transform: rotate(-90deg);
  width: 8px;
  height: 8px;
  margin-left: 5px;
  margin-bottom: 2px;
`

const DocLink = styled.a`
  color: rgba(18, 22, 26, 0.7);
  text-decoration: none;
  :hover {
    color: #f0640c;
  }
`

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

const Docs = ({ docs }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()
  useOnClickOutside(ref, () => setIsOpen(false))

  return (
    <DocsWrapper onClick={() => setIsOpen(!isOpen)}>
      <div style={{ display: 'flex', alignItems: 'center' }} ref={ref}>
        Есть <RightArrow />
      </div>
      {isOpen ? (
        <DocsStyled>
          {docs &&
            docs.map(item => (
              <DocLink
                key={item.id}
                download
                href={`${StaticFilesURL}${item.uri}`}
              >
                {item.title}
              </DocLink>
            ))}
        </DocsStyled>
      ) : null}
    </DocsWrapper>
  )
}

export default Docs
