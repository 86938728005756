import React from 'react'
import styled from 'styled-components'
import { NumericFormat as NumberFormat } from 'react-number-format'

const TextFieldWrapper = styled.div`
  position: relative;
  height: 36px;
  width: 260px;
  margin-bottom: 20px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`

const TextField = styled.input`
  height: 36px;
  width: 260px;
  background: #ffffff;
  border: 1px solid #f49355;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 12px 8px 12px;
  outline: 0;
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);

  ::placeholder {
    font-family: FuturaBookC, sans-serif;
    font-size: 14px;
    line-height: 15px;
    color: rgba(18, 22, 26, 0.4);
    opacity: 0.8;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`

const Label = styled.div`
  position: absolute;
  top: -7px;
  left: 9px;
  padding: 0 4px;
  background: #ffffff;
  font-family: FuturaBookC, sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
`

const StyledNumberField = styled(NumberFormat)`
  height: 36px;
  width: 260px;
  background: #ffffff;
  border: 1px solid #f49355;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 12px 8px 12px;
  outline: 0;
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`

const UserTextField = ({ label, isPhone, onClick, ...rest }) => {
  return (
    <TextFieldWrapper onClick={onClick}>
      {(rest.value || isPhone) && <Label>{label}</Label>}
      {isPhone ? (
        <StyledNumberField
          format="+# (###) ###-##-##"
          allowEmptyFormatting
          mask="_"
          {...rest}
        />
      ) : (
        <TextField placeholder={rest.value ? '' : label} {...rest} />
      )}
    </TextFieldWrapper>
  )
}

export default UserTextField
