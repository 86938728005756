import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { Button, Modal, TextField } from '@material-ui/core'
import { NumericFormat as NumberFormat } from 'react-number-format'

import {
  createStorageProviderRequest,
  deleteStorageProviderRequest,
  updateStorageProviderRequest,
} from 'modules/storages'
import assets from 'assets'

import { useStyles } from './styles'
import WeekField from './Week'
import StorageTextField from './storage-text-field'

const Wrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    overflow: auto;
    margin-bottom: 25px;
    box-sizing: border-box;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10px;
`

const CreateTitle = styled.div`
  padding: 11px 36px 9px 20px;
  background: #ffffff;
  width: 300px;
  height: 36px;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.4);
`

const DeleteIcon = styled.img.attrs({ src: assets.img.deleteColorIcon })`
  display: block;
  width: 16px;
  height: 18px;
  cursor: pointer;
`

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 5px 20px;
  width: 300px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  box-sizing: border-box;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`

const ControlButtons = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
`

const ButtonWrapper = styled.div`
  display: flex;
`

const Container = styled.div`
  width: 516px;
  height: 245px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TextContainer = styled.div`
  margin-top: 70px;
  font-family: FuturaBookC, sans-serif;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #3d4751;
  font-weight: normal;
`

const ButtonsContainer = styled.div`
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  background: #fff;
  justify-content: center;
`

const ConfirmButton = styled.button`
  width: 95px;
  height: 36px;
  background: linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  border-radius: 8px;
  outline: 0;
  border: 0;
  font-family: FuturaMediumC, sans-serif;
  font-size: 17px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
`

const CancelButton = styled(ConfirmButton)`
  background: linear-gradient(0deg, #9ba1a6, #9ba1a6),
  linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  margin-left: 8px;
  cursor: pointer;
`

const Row = styled.div`
  flex-direction: row;
  display: flex;
  flex: 0;
  gap: 8px;
`

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      format='+# (###) ###-##-##'
      allowEmptyFormatting
      mask='_'
    />
  )
}

const defaultFormData = {
  id: '',
  address: '',
  isActive: false,
  name: '',
  phoneOwner: '',
  phoneProvider: '',
  week: [],
  timeStart: '',
  timeEnd: '',
}

const ProviderForm = ({
                        data,
                        visible,
                        onClose,
                        updateProvider,
                        createProvider,
                        deleteProvider,
                     }) => {
  const classes = useStyles()

  const isEdit = !!data?.id;

  const [visibleDeleteAlert, setVisibleDeleteAlert] =  useState(false)

  const [formData, setFormData] = useState(defaultFormData)

  useEffect(() => {
    if (isEdit) {
      setFormData({
        ...data
      });
    }
  }, [data, isEdit, visible]);

  const handleChange = (key, value) => setFormData({ ...formData, [key]: value });

  const handleClose = () => {
    setFormData(defaultFormData);
    onClose();
  }

  const handleSubmit = e => {
    e.preventDefault()
    const action = isEdit ? updateProvider : createProvider;

    action(formData);
    handleClose();
  }

  const handleDelete = () => {
    setVisibleDeleteAlert(false)
    deleteProvider({ providerId: formData.id, storageId: formData.storageId })
    handleClose()
  }

  if (!visible) {
    return null;
  }

  return (
    <Wrapper>
      <Modal
        open={visibleDeleteAlert}
        onClose={() => setVisibleDeleteAlert(false)}
      >
        <Container>
          <TextContainer>
            Удалить поставщика <br />
            {formData.name}?
          </TextContainer>
          <ButtonsContainer>
            <ConfirmButton
              onClick={handleDelete}
            >
              Да
            </ConfirmButton>
            <CancelButton onClick={() => setVisibleDeleteAlert(false)}>
              Отмена
            </CancelButton>
          </ButtonsContainer>
        </Container>
      </Modal>
      <TitleWrapper>
        {!isEdit ? <CreateTitle>Данные Нового поставщика</CreateTitle> : ''}
      </TitleWrapper>
      <FormWrapper>
        <StorageTextField
          label='Название'
          type='text'
          onChange={e => handleChange('name', e.target.value)}
          value={formData.name}
        />
        <StorageTextField
          label='Адрес'
          onChange={e => handleChange('address', e.target.value)}
          value={formData.address}
        />
        <TextField
          type='tel'
          pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
          onChange={e => handleChange('phoneOwner', e.target.value)}
          value={formData.phoneOwner}
          InputLabelProps={{
            shrink: true,
          }}
          classes={{
            root: classes.input,
          }}
          variant='outlined'
          label='Телефон директора'
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
        <TextField
          type='tel'
          pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
          onChange={e => handleChange('phoneProvider', e.target.value)}
          value={formData.phoneProvider}
          InputLabelProps={{
            shrink: true,
          }}
          classes={{
            root: classes.input,
          }}
          variant='outlined'
          label='Телефон менеджера'
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
        <WeekField />
        <Row>
          <TextField
            type='time'
            onChange={e => handleChange('timeStart', e.target.value)}
            value={formData.timeStart}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant='outlined'
            label='Время работы с'
          />
          <TextField
            type='time'
            onChange={e => handleChange('timeEnd', e.target.value)}
            value={formData.timeEnd}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant='outlined'
            label='по'
          />
        </Row>


        <ControlButtons>
          <ButtonWrapper>
            <Button
              onClick={handleSubmit}
              classes={{
                root: classes.addButton,
              }}
            >
              {isEdit ? 'Сохранить' : 'Добавить'}
            </Button>
            <Button
              onClick={handleClose}
              classes={{
                root: classes.cancelButton,
              }}
            >
              Отмена
            </Button>
          </ButtonWrapper>
          {isEdit ? <DeleteIcon onClick={() => setVisibleDeleteAlert(true)} /> : ''}
        </ControlButtons>

      </FormWrapper>
    </Wrapper>
  )
}

export default connect(
  R.applySpec({
  }),
  {
    updateProvider: updateStorageProviderRequest,
    createProvider: createStorageProviderRequest,
    deleteProvider: deleteStorageProviderRequest,
  },
)(ProviderForm)