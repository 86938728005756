import React, { useEffect, useState, useRef } from 'react'
import {
  Button,
  InputAdornment,
  Modal,
  TextField,
  Checkbox,
  withStyles,
} from '@material-ui/core'
import moment from 'moment'
import { connect } from 'react-redux'
import * as R from 'ramda'
import styled, { css } from 'styled-components'
import { Field, reduxForm, getFormValues, reset } from 'redux-form'
import { format } from 'date-fns'
import { NumericFormat as NumberFormat } from 'react-number-format'

import SearchSelect from '../search-select'
import { Option } from '../boards-select'
import CalendarModal from './calendar-modal'
import { Address } from '../../address'
import TimeModal from './time-modal'
import SearchSelectDropDown from '../../common/SearchSelectDropDown'
import { getProductsList } from '../../../modules/products'
import { getStorageRequest, getStorage } from '../../../modules/storage'
import { getAmoUsersList, selectRole } from '../../../modules/users'
import { FORMS } from '../../../constants'
import {
  createOrderRequest,
  editOrderRequest,
  getCurrentOrder,
  getOrderRequest,
} from '../../../modules/orders'

import { ReactComponent as Clock } from '../../../assets/img/clock.svg'
import { ReactComponent as Calendar } from '../../../assets/img/calendar.svg'
import { ReactComponent as Phone } from '../../../assets/img/phoneIcon.svg'
import assets from '../../../assets'
import { useStyles } from './styles'
import { getOrganizationList } from '../../../modules/organizations'
import { getScheduleRequest } from '../../../modules/schedule'
import { getTransportList } from '../../../modules/transport'
import Documents from '../../documents/documentsList'

const OrangeCheckbox = withStyles({
  root: {
    color: '#F0640C',
    '&$checked': {
      color: '#F0640C',
    },
  },
  checked: {},
})(props => <Checkbox color='default' {...props} />)

const CheckBoxWrapper = styled.div`
  display: flex;
  position: relative;
  vertical-align: center;
  align-items: center;
`

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      format='+# (###) ###-##-##'
      allowEmptyFormatting
      mask='_'
    />
  )
}

const Title = styled.div`
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(0, 0, 0, 0.4);
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`
const TitleWrapper = styled.div`
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`
const EditButton = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ isEdit }) =>
          isEdit
                  ? css`
                    background: linear-gradient(135deg, #f49355 0%, #f0640c 100%);
                    border-radius: 8px;
                  `
                  : css`
                    :hover {
                      background: #ffffff;
                      box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
                      border-radius: 6px;
                    }
                  `}
`

const EditRedIcon = styled.img.attrs({ src: assets.img.pencilRed })``
const EditIcon = styled.img.attrs({ src: assets.img.pencil })``

const getModalStyle = () => {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 0,
  }
}

const initialState = {
  id: '',
  product: '',
  count: 0,
  price: 0,
  managerId: '',
  comment: '',
  recipientPhone: '',
  recipientFIO: '',
  organizationId: '',
  date: {
    from: 'До',
    to: '11:00',
    date: format(new Date(), 'yyyy-MM-dd'),
    text: format(new Date(), 'yyyy-MM-dd'),
  },
  payDriverPerUnit: 0,
  totalDriverPayment: 0,
  ttnStatus: '',
  // storageId: '',
  managerComment: '',
  ttn: '',
  volume: '',
  status: '',
  fixedAt: '',
  additionalPrice: 0,
}

const OrderModal = ({
                      open,
                      handleClose,
                      type,
                      id,
                      products,
                      selectRole,
                      users,
                      formValues,
                      createOrder,
                      handleGetOrders,
                      resetForm,
                      getOrder,
                      order,
                      initialize,
                      editOrder,
                      drivers,
                      organizations,
                      getSchedule,
                      getStorages,
                      storages,
                      setModalShow,
                      editAccess,
                      ActiveTab,
                    }) => {
  const totalPay = useRef(null)
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const [orderState, setOrderState] = useState(initialState)
  const [count, setCount] = useState([])
  const [managers, setManagers] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [isError, setIsError] = useState('')
  const [
    isSelectTtnStatusModalOpen,
    setIsSelectTtnStatusModalOpen,
  ] = React.useState(false)
  const [
    isSelectProductModalOpen,
    setIsSelectProductModalOpen,
  ] = React.useState(false)
  const [isSelectUserModalOpen, setIsSelectUserModalOpen] = React.useState(
    false,
  )
  const [isSelectDriverModalOpen, setIsSelectDriverModalOpen] = React.useState(
    false,
  )
  const [
    isSelectManagerModalOpen,
    setIsSelectManagerModalOpen,
  ] = React.useState(false)
  const [
    isSelectStorageModalOpen,
    setIsSelectStorageModalOpen,
  ] = React.useState(false)
  const [showDriverPayInfo, setShowDriverPayInfo] = useState(orderState.totalDriverPayment > 0)

  const handleCheckBoxChange = () => {
    setShowDriverPayInfo(!showDriverPayInfo)
  }
  useEffect(() => {
    getSchedule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getStorages()
  }, [])
  useEffect(() => {
    if (managers.length < 1) {
      selectRole('Менеджер')
    }
    setManagers(users)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])
  const [dateOpen, setDateOpen] = useState(false)
  const [timeOpen, setTimeOpen] = useState(false)
  const [driver, setDriver] = useState({
    name: '',
    phone: '',
  })
  const [customer, setCustomer] = useState({ fio: '', phone: '' })

  const ttnStatuses = [
    { title: 'Есть' },
    { title: 'Нет' },
    { title: 'Проверено' },
    { title: 'На исправление' },
  ].filter(e => e.title !== orderState.ttnStatus)
  useEffect(() => {
    if (id && type !== 'CREATE') {
      getOrder({ orderId: id })
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  useEffect(() => {
    if (type === 'CREATE') {
      setOrderState(initialState)
    }
    setDriver({ name: '', phone: '' })
  }, [type])
  useEffect(() => {
    if (Object.keys(order).length > 0 && type !== 'CREATE') {
      setOrderState({
        id: order.id,
        product: order.product.id,
        count: order.count,
        price: order.price,
        // storageId: order.storage ? order.storage.id : '',
        managerId: order.manager ? order.manager.id : '',
        ttnStatus: order.ttnStatus ? order.ttnStatus : '',
        managerComment: order.managerComment ? order.managerComment : '',
        comment: order.comment,
        recipientPhone: order.recipientPhone,
        additionalPrice: order.additionalPrice,
        recipientFIO: order.recipientFIO,
        date: JSON.parse(order.date),
        payDriverPerUnit: order.payDriverPerUnit,
        totalDriverPayment: order.totalDriverPayment,
        ttn: order.ttn,
        volume: order.volume,
        status: order.status,
        fixedAt: order.fixedAt,
        organizationId: order.organization
          ? organizations.find(item => item.title === order.organization.title)
            .id
          : '',
      })
      setDriver({
        name: (order.transport && order.transport.id) || '',
        phone:
          (drivers.find(
              item => order.transport && item.transportId === order.transport.id,
            ) &&
            drivers.find(item => item.transportId === order.transport.id)
              .phone) ||
          '',
      })
      setCustomer({
        fio: order.user.fio,
        phone: order.user.phone,
      })
      let countArr = []
      for (
        let i = Number(
          products.find(item => item.id === order.product.id)?.multiplicity,
        );
        i < products.find(item => item.id === order.product.id).count;
        i =
          i +
          Number(
            products.find(item => item.id === order.product.id).multiplicity,
          )
      ) {
        countArr.push(i)
      }
      setCount(countArr)
      initialize({
        address: JSON.parse(order.address),
      })
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  const handlePayDriverChange = e => {
    if (/^\d*\.?\d*$/.test(e.target.value)) {
      setOrderState({
        ...orderState,
        payDriverPerUnit: e.target.value,
        totalDriverPayment: Number(e.target.value) * orderState.count,
      })
    }
  }

  const handleCountChange = e => {
    setOrderState({
      ...orderState,
      count: Number(e.target.value),
      price:
        Number(e.target.value) *
        Number(products.find(item => item.id === orderState.product).price),
    })
  }

  const handleDateChange = date => {
    setOrderState({
      ...orderState,
      date: { ...orderState.date, date: date, text: date },
    })
  }

  const handleTimeChange = time => {
    setOrderState({
      ...orderState,
      date: { ...orderState.date, ...time },
    })
  }
  const setActiveItemFromSelect = (name, activeItem) => {
    switch (name) {
      case 'product': {
        setOrderState({
          ...orderState,
          product: activeItem,
          count: Number(products.find(item => item.id === activeItem).minCount),
          price:
            Number(products.find(item => item.id === activeItem).multiplicity) *
            products.find(item => item.id === activeItem).price,
        })
        let countArr = []
        for (
          let i = Number(
            products.find(item => item.id === activeItem).minCount,
          );
          i < products.find(item => item.id === activeItem).count;
          i =
            i +
            Number(products.find(item => item.id === activeItem).multiplicity)
        ) {
          countArr.push(i)
        }
        setCount(countArr)
        break
      }
      case 'organization': {
        setOrderState({ ...orderState, organizationId: activeItem })
        break
      }
      case 'manager': {
        setOrderState({ ...orderState, managerId: activeItem })
        break
      }
      case 'driver': {
        const currDriver = drivers.find(item => item.transportId === activeItem)
        setDriver({ phone: currDriver.phone, name: activeItem })
        break
      }
      // case 'storage': {
      //   setOrderState({ ...orderState, storageId: activeItem })
      //   break
      // }
      case 'ttnStatus': {
        setOrderState({ ...orderState, ttnStatus: activeItem })
        break
      }
      default: {
        console.log(name, activeItem)
      }
    }
  }
  const handleSubmit = () => {
    const data = {
      comment: orderState.comment,
      orders: [
        {
          productId: orderState.product,
          count: orderState.count,
          comment: orderState.comment,
          date: orderState.date,
          address: formValues?.address,
          city: 'Краснодар',
          recipientPhone: orderState.recipientPhone,
          recipientFIO: orderState.recipientFIO,
          managerId: orderState.managerId,
          organizationId: orderState.organizationId,
          transportId: driver.name,
          payDriverPerUnit: orderState.payDriverPerUnit,
          totalDriverPayment: orderState.totalDriverPayment,
          // storageId: orderState?.storageId,
          additionalPrice: orderState.additionalPrice,
          managerComment: orderState.managerComment,
          ttnStatus: orderState.ttnStatus,
          // driverPhone: driver.phone,
        },
      ],
    }
    const editData = {
      id: id,
      productId: orderState.product,
      count: orderState.count,
      comment: orderState.comment,
      date: orderState.date,
      address: formValues?.address,
      city: 'Краснодар',
      recipientPhone: orderState.recipientPhone,
      recipientFIO: orderState.recipientFIO,
      managerId: orderState.managerId,
      organizationId: orderState.organizationId,
      transportId: driver.name,
      payDriverPerUnit: orderState.payDriverPerUnit,
      totalDriverPayment: orderState.totalDriverPayment,
      // storageId: orderState?.storageId,
      additionalPrice: orderState.additionalPrice,
      managerComment: orderState.managerComment,
      volume: orderState.volume,
      ttnStatus: orderState.ttnStatus,
      // driverPhone: driver.phone,
    }

    if (type === 'VIEW' && editMode) {
      editOrder({
        boardId: '',
        data: editData,
      })
      resetForm()
      handleClose()
      setEditMode(false)
      setTimeout(() => {
        handleGetOrders()
      }, 700)
    } else {
      if (
        data.orders[0].productId &&
        data.orders[0].address &&
        data.orders[0].recipientFIO &&
        data.orders[0].recipientPhone // &&
        // data.orders[0]?.storageId
      ) {
        createOrder({
          boardId: '',
          data: data,
        })
        setOrderState(initialState)
        resetForm()
        handleClose()
        setEditMode(false)
        setTimeout(() => {
          handleGetOrders()
        }, 700)
        setIsError('')
      } else {
        setIsError(
          `Заполните следующие поля: ${
            data.orders[0].productId ? '' : 'Товар; '
          }${data.orders[0].address ? '' : 'Адрес; '}${
            data.orders[0].recipientFIO ? '' : 'ФИО приемщика; '
          }${data.orders[0].recipientPhone ? '' : 'Телефон приемщика; '}`,
          // ${data.orders[0]?.storageId ? '' : 'Склад отгрузки; '}
        )
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose()
        setEditMode(false)
      }}
    >
      <div style={modalStyle} className={classes.paper}>
        <TitleWrapper>
          <Title>{type === 'CREATE' ? 'НОВАЯ ЗАЯВКА' : `№${id}`}</Title>
          {type === 'VIEW' && (
            <EditButton
              onClick={() =>
                editAccess ? setEditMode(!editMode) : setModalShow(true)
              }
              isEdit={editMode}
            >
              {editMode ? <EditIcon /> : <EditRedIcon />}
            </EditButton>
          )}
        </TitleWrapper>
        <SearchSelectDropDown
          disabled={type === 'VIEW' && !editMode}
          items={products}
          setActiveItem={setActiveItemFromSelect}
          isSelectModalOpen={isSelectProductModalOpen}
          setIsSelectModalOpen={setIsSelectProductModalOpen}
          defaultValue={orderState.product || ''}
          visibleObjectName={'title'}
          hideObjectName={'id'}
          name={'product'}
          shrinkLabel={'Товар'}
          shrinkStyles={{
            color: 'rgba(0, 0, 0, 0.54)',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          }}
          styles={{ width: 395 }}
        />
        <Row>
          <SearchSelect
            disabled={type === 'VIEW' && !editMode}
            value={orderState.count}
            onChange={handleCountChange}
            transformX={-5}
            label='Количество'
            width={120}
          >
            {count.map(item => (
              <Option key={item} value={item}>
                {item}{' '}
                {products.length > 0 &&
                  products.find(item => item.id === orderState.product) &&
                  products.find(item => item.id === orderState.product)
                    .measurement}
              </Option>
            ))}
          </SearchSelect>
          <TextField
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            style={{ width: '120px' }}
            variant='outlined'
            label='Стоимость'
            value={`${orderState.price} ₽`}
          />
          <TextField
            onChange={e =>
              setOrderState({
                ...orderState,
                additionalPrice: e.target.value.replace(/\D/g, ''),
              })
            }
            disabled={type === 'VIEW' && !editMode}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant='outlined'
            label='Надбавка'
            style={{ width: '120px' }}
            value={`${
              orderState.additionalPrice === null
                ? 0
                : orderState.additionalPrice
            }`}
          />
        </Row>
        <Row>
          <TimeModal
            date={orderState.date.date}
            initialValue={{
              from: orderState.date.from,
              to: orderState.date.to,
            }}
            open={timeOpen}
            handleClose={() => setTimeOpen(false)}
            handleChange={handleTimeChange}
          />
          <CalendarModal
            initialValue={orderState.date.text}
            open={dateOpen}
            handleClose={() => setDateOpen(false)}
            handleChange={handleDateChange}
          />
          <TextField
            disabled={type === 'VIEW' && !editMode}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Calendar />
                </InputAdornment>
              ),
            }}
            variant='outlined'
            label='Дата доставки'
            value={orderState.date.text}
            onClick={() => {
              if ((type === 'VIEW' && editMode) || type === 'CREATE') {
                setDateOpen(true)
              }
            }}
          />
          <TextField
            disabled={type === 'VIEW' && !editMode}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant='outlined'
            label='Время'
            value={`${orderState.date.from} ${orderState.date.to}`}
            onClick={() => {
              if ((type === 'VIEW' && editMode) || type === 'CREATE') {
                setTimeOpen(true)
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Clock />
                </InputAdornment>
              ),
            }}
          />
        </Row>
        <Row>
          <Field
            disabled={type === 'VIEW' && !editMode}
            name={`address`}
            component={Address}
          />
        </Row>
        <Row>
          <SearchSelectDropDown
            disabled={type === 'VIEW' && !editMode}
            items={organizations}
            setActiveItem={setActiveItemFromSelect}
            isSelectModalOpen={isSelectUserModalOpen}
            setIsSelectModalOpen={setIsSelectUserModalOpen}
            defaultValue={orderState.organizationId || ''}
            visibleObjectName={'title'}
            hideObjectName={'id'}
            name={'organization'}
            shrinkLabel={'Юр. лицо'}
            shrinkStyles={{
              color: 'rgba(0, 0, 0, 0.54)',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
            styles={{ width: 397 }}
          />
        </Row>
        <Row>
          <SearchSelectDropDown
            disabled={type === 'VIEW' && !editMode}
            items={managers}
            setActiveItem={setActiveItemFromSelect}
            isSelectModalOpen={isSelectManagerModalOpen}
            setIsSelectModalOpen={setIsSelectManagerModalOpen}
            defaultValue={orderState.managerId || ''}
            visibleObjectName={'fio'}
            hideObjectName={'id'}
            name={'manager'}
            shrinkLabel={'ФИО менеджера'}
            shrinkStyles={{
              color: 'rgba(0, 0, 0, 0.54)',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
            styles={{ width: 200 }}
          />
          <TextField
            disabled={type === 'VIEW' && !editMode}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant='outlined'
            label='Ответственный'
            value={'Карамида'}
          />
        </Row>
        <Row>
          <TextField
            disabled={type === 'VIEW' && !editMode}
            value={orderState.recipientFIO}
            onChange={e =>
              setOrderState({ ...orderState, recipientFIO: e.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant='outlined'
            label='ФИО приёмщика'
          />
          <TextField
            disabled={type === 'VIEW' && !editMode}
            value={orderState.recipientPhone}
            onChange={e =>
              setOrderState({ ...orderState, recipientPhone: e.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant='outlined'
            label='Телефон приёмщика'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Phone />
                </InputAdornment>
              ),
              inputComponent: NumberFormatCustom,
            }}
          />
        </Row>
        <Row>
          <SearchSelectDropDown
            disabled={type === 'VIEW' && !editMode}
            items={drivers}
            setActiveItem={setActiveItemFromSelect}
            isSelectModalOpen={isSelectDriverModalOpen}
            setIsSelectModalOpen={setIsSelectDriverModalOpen}
            defaultValue={driver.name || ''}
            visibleObjectName={'fio'}
            hideObjectName={'transportId'}
            name={'driver'}
            shrinkLabel={'ФИО водителя'}
            shrinkStyles={{
              color: 'rgba(0, 0, 0, 0.54)',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
            styles={{ width: 200 }}
          />
          <TextField
            onChange={e => setDriver({ ...driver, phone: e.target.value })}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant='outlined'
            label='Телефон водителя'
            value={driver.phone}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: (
                <InputAdornment position='end'>
                  <Phone />
                </InputAdornment>
              ),
            }}
          />
        </Row>
        <Row>
          <TextField
            disabled={true}
            value={customer.fio}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant='outlined'
            label='ФИО заказчика'
          />
          <TextField
            disabled={true}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant='outlined'
            label='Телефон заказчика'
            value={customer.phone}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: (
                <InputAdornment position='end'>
                  <Phone />
                </InputAdornment>
              ),
            }}
          />
        </Row>
        <Row>
          <span
            style={{
              border: '1px solid rgba(136, 136, 136, 0.2)',
              width: '100%',
            }}
          />
        </Row>
        <Row style={{ marginBottom: '20px' }}>
          <SearchSelectDropDown
            disabled={type === 'VIEW' && !editMode}
            items={ttnStatuses}
            setActiveItem={setActiveItemFromSelect}
            isSelectModalOpen={isSelectTtnStatusModalOpen}
            setIsSelectModalOpen={setIsSelectTtnStatusModalOpen}
            defaultValue={orderState.ttnStatus}
            visibleObjectName={'title'}
            hideObjectName={'title'}
            name={'ttnStatus'}
            shrinkLabel={'ТТН'}
            shrinkStyles={{
              color: 'rgba(0, 0, 0, 0.54)',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
            styles={{ width: 135 }}
            notSorted={true}
            withoutSearch={true}
          />
          {orderState.ttnStatus !== 'Нет' && orderState.ttn && (
            <Row style={{ marginTop: '0px', marginRight: '150px' }}>
              <Documents
                isView={true}
                documents={[
                  {
                    id: 1,
                    title: 'Посмотреть',
                    uri: order.ttn,
                  },
                ]}
              />
            </Row>
          )}
        </Row>
        <Row>
          {orderState.ttnStatus === 'На исправление' &&
            orderState.status === 'toFix' && (
              <Row style={{ marginTop: '0px' }}>
                {orderState.fixedAt ? (
                  <span
                    style={{ color: 'green' }}
                  >{`Исправлено водителем ${moment(orderState.fixedAt).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}`}</span>
                ) : (
                  <span style={{ color: 'red' }}>{'Не исправлено'}</span>
                )}
              </Row>
            )}
        </Row>
        {orderState.ttnStatus === 'На исправление' && (
          <Row>
            <TextField
              disabled={type === 'VIEW' && !editMode}
              value={orderState.managerComment}
              onChange={e =>
                setOrderState({
                  ...orderState,
                  managerComment: e.target.value,
                })
              }
              multiline
              rows={1}
              InputLabelProps={{
                shrink: true,
              }}
              classes={{
                root: classes.fullWidthInput,
              }}
              variant='outlined'
              label='Комментарий менеджера'
            />
          </Row>
        )}
        {ActiveTab === 'completed' && (
          <Row>
            <TextField
              disabled={type === 'VIEW' && !editMode}
              transformX={-5}
              label='Обьем факт.'
              pa
              width={200}
              value={orderState.volume}
              onChange={e => {
                const re = /[A-Za-zА-Яа-яЁё]/
                if (e.target.value === '' || !re.test(e.target.value)) {
                  setOrderState({ ...orderState, volume: e.target.value })
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              classes={{
                root: classes.input,
              }}
              variant='outlined'
            />
            <TextField
              label='Стоимость факт. '
              value={
                orderState.volume
                  ? (Number(orderState.price) / Number(orderState.count)) *
                  Number(orderState.volume)
                  : 'Отсутствует'
              }
              disabled={true}
              InputLabelProps={{
                shrink: true,
              }}
              classes={{
                root: classes.input,
              }}
              variant='outlined'
            />
          </Row>
        )}
        <Row>
          <CheckBoxWrapper>
            <OrangeCheckbox
              disabled={type === 'VIEW' && !editMode}
              checked={showDriverPayInfo}
              // onChange={handleChange}
              onChange={handleCheckBoxChange}
            />
            <span style={{ fontFamily: 'FuturaBookC' }}>
              {'Указать стоимость рейса'}
            </span>
          </CheckBoxWrapper>
        </Row>
        {showDriverPayInfo ? (
          <Row>
            <TextField
              disabled={type === 'VIEW' && !editMode}
              transformX={-5}
              label='Оплата за единицу, руб'
              width={200}
              value={orderState.payDriverPerUnit}
              onChange={handlePayDriverChange}
              InputLabelProps={{
                shrink: true,
              }}
              classes={{
                root: classes.input,
              }}
              variant='outlined'
            />
            <TextField
              label='Оплата за рейс, руб '
              value={orderState.totalDriverPayment}
              ref={totalPay}
              disabled={true}
              InputLabelProps={{
                shrink: true,
              }}
              classes={{
                root: classes.input,
              }}
              variant='outlined'
            />
          </Row>
        ) : null}
        {/*        <Row>
          <SearchSelectDropDown
            disabled={type === 'VIEW' && !editMode}
            items={storages}
            setActiveItem={setActiveItemFromSelect}
            isSelectModalOpen={isSelectStorageModalOpen}
            setIsSelectModalOpen={setIsSelectStorageModalOpen}
            defaultValue={orderState.storageId || ''}
            visibleObjectName={'title'}
            hideObjectName={'id'}
            name={'storage'}
            shrinkLabel={'Склад отгрузки'}
            shrinkStyles={{
              color: 'rgba(0, 0, 0, 0.54)',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
            styles={{ width: 412 }}
          />
        </Row>*/}
        <Row>
          <TextField
            disabled={type === 'VIEW' && !editMode}
            value={orderState.comment}
            onChange={e =>
              setOrderState({
                ...orderState,
                comment: e.target.value,
              })
            }
            multiline
            rows={2}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.fullWidthInput,
            }}
            variant='outlined'
            label='Комментарий'
          />
        </Row>
        <div className={classes.error}>{isError}</div>
        {((type === 'VIEW' && editMode) || type === 'CREATE') && (
          <div className={classes.buttonWrapper}>
            <Button
              onClick={handleSubmit}
              classes={{
                root: classes.addButton,
              }}
            >
              Создать
            </Button>
            <Button
              onClick={handleClose}
              classes={{
                root: classes.cancelButton,
              }}
            >
              Отмена
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default R.compose(
  connect(
    R.applySpec({
      products: getProductsList,
      users: getAmoUsersList,
      formValues: state => getFormValues(FORMS.ORDER_MODAL)(state),
      order: getCurrentOrder,
      drivers: getTransportList,
      organizations: getOrganizationList,
      storages: getStorage,
    }),
    {
      getStorages: getStorageRequest,
      selectRole: selectRole,
      createOrder: createOrderRequest,
      editOrder: editOrderRequest,
      resetForm: () => reset(FORMS.ORDER_MODAL),
      getOrder: getOrderRequest,
      getSchedule: getScheduleRequest,
    },
  ),
  reduxForm({ form: FORMS.ORDER_MODAL }),
)(OrderModal)
